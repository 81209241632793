<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px)">
                    <a-layout style="height:100%;background:#fff;padding:0 20px;">
                        <a-layout-header style="height:auto;background:#fff;padding:16px 0 ;">
                            <a-row>
                                <a-col :span="20">
                                    <a-form layout="horizontal" style="padding:0 20px;height:auto;">
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="类型">
                                                    <a-radio-group default-value="MaterialType" v-model="MaterialType" @change="QueryData">
                                                        <a-radio-button value="-1">全部</a-radio-button>
                                                        <a-radio-button value="0">朋友圈</a-radio-button>
                                                        <a-radio-button value="1">推广文章</a-radio-button>
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                        <a-form-item label="" style="margin-bottom:0px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="素材">
                                                    <a-input placeholder="输入素材标题" v-model="Name" :value="Name" style="width:278px;" />
                                                </a-form-item>
                                                <a-form-item label="">
                                                    <a-button type="primary" class="margin-r" @click="QueryData">
                                                        查询
                                                    </a-button>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>

                                    </a-form>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <a-table bordered :columns="columns" rowKey="ID" :data-source="Material" :pagination="pagination" @change="pagerChange">
                            <span slot="action" slot-scope="text, record">
                                <a-button type="link" size="small" @click="EditRow(record)">详情</a-button>
                            </span>
                        </a-table>
                    </a-layout>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "PackageHistoryList",
        data() {
            return {
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                MaterialType: "-1",
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                Material: [],
                Name: "",
                columns: [
                    {
                        title: "素材图片",
                        width: 1,
                        align: "center",
                        customRender: (text, item) => {
                            var img = item.ImgTopic;
                            var logo = item.Logo;
                            if (item.MaterialType == 0) {
                                img = img.replace("upload", "upload2");
                                return <div><img src={img} width="60" height="60" /></div>
                            }
                            if (item.MaterialType == 1) {
                                logo = logo.replace("upload", "upload2");
                                return <div><img src={logo} width="60" height="60" /></div>
                            }
                        }
                    },
                    {
                        title: "素材标题",
                        dataIndex: "Name",
                        width: 100
                    },
                    {
                        title: "创建时间",
                        align: "center",
                        width: 110,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "素材类型",
                        width: 1,
                        align: "center",
                        customRender: (text, item) => {
                            var Status = item.MaterialType;
                            if (Status == "0") {
                                return <div>朋友圈</div>
                            } else {
                                return <div>软文推广</div>
                            }
                        }
                    },
                    {
                        title: "发布时间",
                        align: "center",
                        width: 110,
                        customRender: (text, item) => {
                            var Time = "未发布";
                            if (item.PushState == 1) {
                                Time = util.TimeStamptoDateTime(item.PushTime, 'yyyy-MM-dd hh:mm:ss')
                            }
                            return Time
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        scopedSlots: { customRender: 'action' },
                        width: 130
                    }
                ],
                ID: 0,
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            QueryData() {
                var self = this;
                self.pagination.current = 1;
                this.loadData();
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialQueueList",
                    data: {
                        MaterialType: self.MaterialType,
                        Name: self.Name,
                        PageIndex: self.pagination.current,
                        PackageID: self.ID
                    },
                    OnSuccess: function (res) {
                        self.Material = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },
            EditRow(e) {
                var id = e.ID;
                var type = e.MaterialType;
                if (type == 0) {
                    this.$router.push({
                        name: "NewMaterial_MomentDetail",
                        query: { id: 0, QueueID: id, disabled: 1 }
                    });
                }
                if (type == 1) {
                    this.$router.push({
                        name: "NewMaterial_ExtensionDetail",
                        query: { id: 0, QueueID: id, disabled: 1 }
                    });
                }
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            this.ID = this.$route.query.id;
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.loadData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>